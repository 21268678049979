@import '../../../variables';

.dark-theme {
    --card-background: #1F1C2B;
    --title-primary: #fff;
    --sub-title: #FFF;
    --card-body-name: #84849D;
    --card-body-value: #FFFFFF;
    --card-btn-border: #E85F14;
    --card-btn-text: #ffffff;
    --card-text-white: #ffffff;
    --card-wallet-name: rgba(255, 255, 255, 0.8);
    --card-wallet-value: #FFFFFF;
    --button-hover: rgba(255, 255, 255, 0.5);
    --button-border: rgba(255, 255, 255, 0.5);
    --social-wrap: rgba(31, 28, 43, 0.6);
    --social-wrap-shadow: rgba(31, 28, 43, 0.6);
    --social-wrap-inset: rgba(31, 28, 43, 0.6);
    --social-icon: rgba(31, 28, 43, 0.6);
    --social-icon: #FFF;
  }
  
  .light-theme {
    --card-background: #fff;
    --title-primary: #1E1E1E;
    --sub-title: #848484;
    --card-body-name: #848484;
    --card-body-value: #1E1E1E;
    --card-btn-border: #E85F14;
    --card-btn-text: #000;
    --card-text-white: #1E1E1E;
    --card-wallet-name: #848484;
    --card-wallet-value: #1E1E1E;
    --button-hover: #5ba6fc;
    --button-border: #5ba6fc;
    --social-wrap: #F7F8FE;
    --social-wrap-shadow: #bfc8fa;
    --social-wrap-inset: #E85F14;
    --social-icon: #E85F14;
  }

.dashboard {
    &-screen {
        @media (max-width: 600px) {
            height: 100vh;
            // overflow: auto;
        }
        @media (max-width: 420px) {
            height: 100vh;
            // overflow: auto;
        }

        .fs-3 {
            @media (max-width: 1440px) {
                font-size: 20px !important;
            }

            @media (max-width: 1024px) {
                font-size: 15px !important;
            }
        }
    }
    &-wrap {
        position: relative;
        z-index: 9;
        width: calc(100% - 250px);
        // height: calc(100vh - 10px);
        // overflow: auto;
        left: 220px;
        top: 80px;
        transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);
        padding: 50px 105px;
        // overflow: scroll;
        @media (max-width: 1366px) {
            width: calc(100% - 150px);
            left: 150px;
            padding: 50px 135px;
        }

        @media (max-width: 1024px) {
            padding: 30px 120px;
        }

        @media (max-width: 820px) {
            width: calc(100% );
            left: 0px;
            // width: calc(100% - 200px);
            // left: 200px;
            padding: 30px 100px;
        }

        @media (max-width: 720px) {
            width: calc(100% );
            left: 0px;
            // width: calc(100% - 200px);
            // left: 200px;
            padding: 30px 100px;
        }

        @media (max-width: 600px) {
            width: 100%;
            left: 0px;
            top: 116px;
            padding: 30px;
        }

        &.active {
            transform: translate3d(-160px, 0, 0);
            width: 100%;
            transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);

            .search-wrap {
                transition: all .3s ease;
                
                @media (max-width: 600px) {
                    flex-direction: column;
                }

                .btn {
                    margin-left: 0 !important;
                    margin-top: 5px;
                }
                
            }

            .w-57 {
                width: 48%;

                @media (max-width: 1800px) {
                    width: 51%;
                }

                @media (max-width: 1680px) {
                    width: 55%;
                }

                @media (max-width: 1600px) {
                    width: 60%;
                }

                @media (max-width: 1440px) {
                    width: 68%;
                }

                @media (max-width: 1366px) {
                    width: 60%;
                }

                @media (max-width: 1280px) {
                    width: 66%;
                }

                @media (max-width: 1200px) {
                    width: 72%;
                }

                @media (max-width: 1024px) {
                    width: 65%;
                }

                @media (max-width: 820px) {
                    width: 70%;
                }
            }

            @media (max-width: 820px) {
                transform: translate3d(0px, 0, 0);
                // transform: translate3d(-200px, 0, 0);
            }

            @media (max-width: 600px) {
                transform: translate3d(0px, 0, 0);
                width: calc(90%);
                // margin-top: 62px;

                .ration-progress {
                    padding: 0;
                }
            }
            @media (max-width: 420px) {
                transform: translate3d(0px, 0, 0);
                width: calc(90%);
                // margin-top: 62px;

                .ration-progress {
                    padding: 0;
                }
            }
        }
    }

    &-wrap::-webkit-scrollbar{
        display: none;
    }

    &-card {
        // background: url(../../../assets/banner/vault-bg.png);
        background: var(--card-background);
        border-radius: 0px;
        padding: 34px 25px;
        height: 100%;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        // height: 100%;
        transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);

        @media (max-width: 1680px) {
            // border-radius: 20px;
            padding: 30px 20px;
        }

        @media (max-width: 1280px) {
            // border-radius: 15px;
            padding: 25px 15px;
        }

        @media (max-width: 600px) {
            // border-radius: 10px;
            padding: 15px;
        }
    }    
}

.hr-line{
    border: 1px solid #CDCDCD;
}

.title-primary {
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: var(--title-primary);
    margin-bottom: 30px;

    @media (max-width: 1680px) {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 20px;
    }

    @media (max-width: 1280px) {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 15px;
    }

    @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 24px;
    }

    @media (max-width: 820px) {
        font-size: 16px;
        line-height: 22px;
    }

    @media (max-width: 400px) {
        font-size: 11px;
        line-height: 15px;
    }
}

.price-wrap {
    margin-bottom: 30px;
    justify-content: center;
    @media (max-width: 1680px) {
        margin-bottom: 20px;
    }

    @media (max-width: 1280px) {
        margin-bottom: 15px;
    }

    @media (max-width: 400px) {
        font-size: 11px;
        line-height: 15px;
    }

    .card-btn-meta {
        margin-bottom: 0;
        padding: 4px 8px;
        border: 1px solid #FFEFE0;
        background: #FFEFE0;
        border-radius: 40px;
        display: flex;
        align-items: center;
        margin-right: 15px;
        transition: all .3s ease;
    }

    span{
        color: var(--sub-title);
        align-self: center;
    }

    img {
        // font-weight: 400;
        // font-size: 18px;
        // line-height: 21px;
        height: 22px;
        width: 16px;
        color: rgba(255, 255, 255, 0.8);

        @media (max-width: 1680px) {
            font-size: 16px;
            line-height: 26px;
        }

        @media (max-width: 1280px) {
            font-size: 14px;
            line-height: 18px;
        }

        @media (max-width: 1024px) {
            font-size: 12px;
            line-height: 16px;
        }

        span {
            font-weight: 500;
            color: $primary;
        }
    }
}

.card {
    &-body {
        &-row {
            margin-bottom: 20px;

            @media (max-width: 1680px) {
               margin-bottom: 15px;
            }

            @media (max-width: 1280px) {
                margin-bottom: 10px;
            }
        }

        &-name {
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            color: var(--card-body-name);

            @media (max-width: 1680px) {
                font-size: 10px;
                line-height: 26px;
            }

            @media (max-width: 1280px) {
               font-size: 14px;
               line-height: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 10px;
                line-height: 16px;
            }

            @media (max-width: 992px) {
                font-size: 9px;
            }

            @media (max-width: 820px) {
                font-size: 14px;
            }

            @media (max-width: 600px) {
                white-space: nowrap;
                font-size: 11px;
                line-height: 14px;
            }

            @media (max-width: 420px) {
                font-size: 7px;
                line-height: 13px;
                text-align: left;
            }

            svg {
                color: rgba(255, 255, 255, 0.5);
            }
        }

        &-value {
            font-weight: 500;
            font-size: 26px;
            line-height: 21px;
            text-align: center;
            color: var(--card-body-value);

            @media (max-width: 1680px) {
                font-size: 24px;
                line-height: 26px;
            }

            @media (max-width: 1280px) {
                font-size: 16px;
                line-height: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 18px;
                line-height: 16px;
            }

            @media (max-width: 600px) {
                font-size: 11px;
                line-height: 14px;
            }

            @media (max-width: 400px) {
                font-size: 9px;
                line-height: 13px;
            }
        }
    }

    &-footer-info {
        display: flex;
        align-items: center;
        justify-content: center;

        .card-btn {
            margin-bottom: 0;
            padding: 15px 25px;
            border: 1px solid var(--card-btn-border);
            background: var(--card-btn-border);
            border-radius: 25px;
            display: flex;
            align-items: center;
            margin-right: 15px;
            transition: all .3s ease;

            @media (max-width: 820px) {
                margin-right: 5px;
                padding: 6px;
            }

            @media (max-width: 400px) {
                padding: 5px;
            }

            &:hover {
                background: var(--button-hover);
                border-color: var(--button-hover);
            }

            img {
                max-width: 16px;
                max-height: 16px;
                margin-right: 10px;

                @media (max-width: 1200px) {
                    max-width: 14px;
                    max-height: 14px;
                    margin-right: 5px;
                }

                @media (max-width: 1024px) {
                    max-width: 12px;
                    max-height: 12px;
                }

                @media (max-width: 400px) {
                    max-width: 10px;
                    max-height: 10px;
                }
            }

            p {
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;

                @media (max-width: 1200px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 16px;
                }

                @media (max-width: 400px) {
                    font-size: 8px;
                    line-height: 12px;
                }
            }
        }
        .card-btn-stake {
            margin-bottom: 0;
            padding: 15px 25px;
            border: 1px solid var(--card-btn-border);
            border-radius: 25px;
            display: flex;
            align-items: center;
            margin-right: 15px;
            transition: all .3s ease;

            @media (max-width: 820px) {
                margin-right: 5px;
                padding: 6px;
            }

            @media (max-width: 400px) {
                padding: 5px;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.5);
                border-color: var(--button-hover);
            }

            img {
                max-width: 16px;
                max-height: 16px;
                margin-right: 10px;

                @media (max-width: 1200px) {
                    max-width: 14px;
                    max-height: 14px;
                    margin-right: 5px;
                }

                @media (max-width: 1024px) {
                    max-width: 12px;
                    max-height: 12px;
                }

                @media (max-width: 400px) {
                    max-width: 10px;
                    max-height: 10px;
                }
            }

            p {
                font-size: 16px;
                line-height: 19px;
                color: var(--card-btn-text);

                @media (max-width: 1200px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 16px;
                }

                @media (max-width: 400px) {
                    font-size: 8px;
                    line-height: 12px;
                }
            }
        }
    }
}

.border-card-details{
    border-right: 1px solid #84849D;
}

.wallte {
    &-body {
        &-row {
            margin-bottom: 10px;

            @media (max-width: 1680px) {
               margin-bottom: 15px;
            }

            @media (max-width: 1280px) {
                margin-bottom: 10px;
            }
        }

        &-name {
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            color: var(--card-wallet-name);

            @media (max-width: 1680px) {
                font-size: 16px;
                line-height: 26px;
            }

            @media (max-width: 1280px) {
               font-size: 13px;
               line-height: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 12px;
                line-height: 16px;
            }

            @media (max-width: 992px) {
                font-size: 11px;
            }

            @media (max-width: 820px) {
                font-size: 12px;
            }

            @media (max-width: 600px) {
                white-space: nowrap;
                font-size: 11px;
                line-height: 14px;
            }

            @media (max-width: 400px) {
                font-size: 9px;
                line-height: 13px;
            }

            svg {
                color: rgba(255, 255, 255, 0.5);
            }
        }

        &-value {
            font-weight: 500;
            font-size: 26px;
            line-height: 21px;
            text-align: center;
            color: var(--card-wallet-value);

            @media (max-width: 1680px) {
                font-size: 26px;
                line-height: 26px;
            }

            @media (max-width: 1280px) {
                font-size: 16px;
                line-height: 20px;
            }

            @media (max-width: 1024px) {
                font-size: 14px;
                line-height: 16px;
            }

            @media (max-width: 600px) {
                font-size: 12px;
                line-height: 14px;
            }

            @media (max-width: 400px) {
                font-size: 9px;
                line-height: 13px;
            }
        }
    }

    &-footer-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .card-btn {
            margin-bottom: 0;
            padding: 6px 10px;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin-right: 15px;
            transition: all .3s ease;

            @media (max-width: 820px) {
                margin-right: 5px;
                padding: 6px;
            }

            @media (max-width: 400px) {
                padding: 5px;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.5);
                border-color: rgba(255, 255, 255, 0.5);
            }

            img {
                max-width: 16px;
                max-height: 16px;
                margin-right: 10px;

                @media (max-width: 1200px) {
                    max-width: 14px;
                    max-height: 14px;
                    margin-right: 5px;
                }

                @media (max-width: 1024px) {
                    max-width: 12px;
                    max-height: 12px;
                }

                @media (max-width: 400px) {
                    max-width: 10px;
                    max-height: 10px;
                }
            }

            p {
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;

                @media (max-width: 1200px) {
                    font-size: 14px;
                    line-height: 18px;
                }

                @media (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 16px;
                }

                @media (max-width: 400px) {
                    font-size: 8px;
                    line-height: 12px;
                }
            }
        }
    }
}

.title-white {
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
    color: var(--card-text-white);
    margin-bottom: 30px;

    @media (max-width: 1680px) {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
    }

    @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 15px;
    }

    @media (max-width: 1024px) {
        font-size: 18px;
        line-height: 22px;
    }

    @media (max-width: 820px) {
        font-size: 16px;
        line-height: 22px;
    }

    @media (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;

        svg {
            width: 12px;
        }
    }

    @media (max-width: 400px) {
        font-size: 11px;
        line-height: 14px;

        svg {
            width: 8px;
        }
    }
}

.card-btn {
    padding: 10px 15px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-bottom: 30px;
    transition: all .3s ease;

    &:hover {
        background: $primary;
        border-color: $primary;
    }

    @media (max-width: 1680px) {
        margin-bottom: 20px;
    }

    @media (max-width: 1280px) {
        margin-bottom: 15px;
    }

    @media (max-width: 1200px) {
        padding: 7px 13px;
        font-size: 14px;
        line-height: 19px;
        border-radius: 7px;
    }

    @media (max-width: 1024px) {
        font-size: 12px;
        line-height: 16px;
    }

    @media (max-width: 820px) {
        font-size: 9px;
        line-height: 13px;
    }

    @media (max-width: 600px) {
        padding: 5px 10px;
    }
}

.table> :not(:first-child) {
    border-top:  none;
}

.table-dark {
    --bs-table-bg: none;
    border-color: transparent;

    th {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;

        color: rgba(255, 255, 255, 0.8);

        @media (max-width: 1680px) {
            font-size: 16px;
            line-height: 26px;
        }

        @media (max-width: 1280px) {
            font-size: 14px;
            line-height: 20px;
        }

        @media (max-width: 1024px) {
            font-size: 12px;
            line-height: 16px;
        }

        @media (max-width: 768px) {
            font-size: 11px;
            line-height: 15px;
        }

        @media (max-width: 400px) {
            font-size: 9px;
            line-height: 13px;
        }
    }

    td {
        font-size: 16px;
        line-height: 19px;

        @media (max-width: 1680px) {
            font-size: 14px;
            line-height: 18px;
        }

        @media (max-width: 1280px) {
            font-size: 12px;
            line-height: 18px;
        }

        @media (max-width: 1024px) {
            font-size: 11px;
            line-height: 16px;
        }

        @media (max-width: 400px) {
            font-size: 9px;
            line-height: 13px;
        }
    }
}

.sidebar-social-wrap {
    padding: 34px 6px;
    background: var(--social-wrap);
    box-shadow: 0.9px 0.9px 6.9px var(--social-wrap-shadow),
    inset 0.9px 0.9px 3.9px var(--social-wrap-inset);
    border-radius: 15px;
    position: absolute;
    bottom: 40%;
    left: 94%;
    display: inline-grid;
    z-index: 10;

    @media (max-width: 1600px) {
        left: 94%;
    }

    @media (max-width: 1280px) {
        // left: 42px;
        left: 94%;
        padding: 10px 8px;
        border-radius: 12px;
    }

    @media (max-width: 1160px) {
        // left: 42px;
        left: 94%;
        padding: 10px 4px;
        border-radius: 12px;
    }

    @media (max-width: 1024px) {
        // left: 48px;
        left: 94%;
    }

    @media (max-width: 820px) {
        // left: 27px;
        left: 94%;
    }
    @media (max-width: 780px) {
        // left: 27px;
        left: 90%;
    }
    @media (max-width: 700px) {
        // left: 27px;
        left: 89%;
    }

    @media (max-width: 600px) {
        // left: 16px;
        left: 86%;
        padding: 7px 0px;
        border-radius: 8px;
    }

    a {
        width: 25px;
        height: 25px;
        margin: 0 7px;
        margin-top: 10px;
        margin-left: 14px;
        @media (max-width: 1600px) {
            margin: 0 5px;
            width: 25px;
            height: 25px;
            margin-top: 10px;
            margin-left: 14px;
        }

        @media (max-width: 1280px) {
            margin: 0 5px;
            width: 25px;
            height: 25px;
            margin-top: 10px;
        }

        @media (max-width: 1160px) {
            margin: 0 5px;
            width: 25px;
            height: 25px;
            margin-top: 10px;
        }

        @media (max-width: 1020px) {
            margin: 0 5px;
            width: 25px;
            height: 25px;
            margin-top: 10px;
        }

        @media (max-width: 820px) {
            margin: 0 5px;
            width: 25px;
            height: 25px;
            margin-top: 10px;
        }
        @media (max-width: 700px) {
            margin: 0 5px;
            width: 25px;
            height: 25px;
            margin-top: 10px;
            margin-left: 12px;
        }
        @media (max-width: 600px) {
            margin: 0 5px;
            width: 25px;
            height: 25px;
            margin-top: 10px;
            margin-left: 14px;
        }

        &:hover {
            svg {
                color: $primary;
            }
        }

        svg {
            color: var(--social-icon);
            transition: all .3s ease;
        }
    }
}

#gauge-chart5 {
    display: flex;
    align-items: center;
    justify-content: center;
}


.cliam-card-head{
    color: #42FBFE;
}

.usdaosections{
    margin-top: 2.5rem;
    @media (max-width: 2600px) {
            margin-top: 7rem;
        }
    @media (max-width: 2500px) {
            margin-top: 6rem;
        }
    @media (max-width: 2400px) {
            margin-top: 5rem;
        }
    @media (max-width: 1500px) {
            margin-top: 4rem;
        }
    @media (max-width: 300px) {
            margin-top: 1rem;
        }
    
    
}

.tooltip{
    color: var(--card-btn-text) !important;
}

.fa-circle-info{
    color: var(--card-btn-text) !important;
}