@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    padding: 0;
    margin: 0;
}
.dark-theme {
    // --icon-color: #E85F14;
    --table-font-color: #FFFFFF;
    --background-color: #15141A;
    --table-color: #1F1C2B;
    --title-primary: #FFFFFF;
    // --font-color: white;
    // --side-button: #E85F14;
    // --nav-background: #1F1C2B;
    // --hover-font: #B2B4BF;
    // --active-font: #1E1E1E;
    --disabled-color: none;
  }
  
  .light-theme {
    // --icon-color: ##E85F14;
    --table-font-color: #848484;
    --background-color: #E6E9F2;
    --table-color: #FFFFFF;
    --title-primary: #1F1C2B;
    // --font-color: #1E1E1E;
    // --side-button: ##E85F14;
    // --nav-background: #FFF;
    // --hover-font: #B2B4BF;
    // --active-font: #1E1E1E;
    --disabled-color: #E85F14;
  }

body {
    padding: 0;
    margin: 0;
    background: var(--background-color);
    font-family: 'Rubik',
    sans-serif;
    width: 100vw;
    overflow-x: hidden;
    font-size: 18px;
    line-height: 22px;
    color: $white;

    @media (max-width: 1366px) {
        font-size: 15px;
        line-height: 19px;
    }

    @media (max-width: 1024px) {
        font-size: 13px;
        line-height: 17px;
    }

    @media (max-width: 600px) {
        font-size: 9px;
        line-height: 15px;
    }
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
    padding: 0;
    margin: 0;
}

ul, li, ol {
    list-style: none;
    padding: 0;
}

.w-57 {
    width: 57%;

    @media (max-width: 1800px) {
        width: 62%;
    }

    @media (max-width: 1680px) {
        width: 69%;
    }

    @media (max-width: 1440px) {
        width: 90%;
    }

    @media (max-width: 1366px) {
        width: 79%;
    }

    @media (max-width: 1280px) {
        width: 87%;
    }

    @media (max-width: 1200px) {
        width: 98%;
    }

    @media (max-width: 1024px) {
        width: 90%;
    }
}

.w-fit {
    width: fit-content;
}

.padding-x {
    padding-left: 200px;
    padding-right: 200px;

    @media (max-width: 1680px) {
        padding-left: 180px;
        padding-right: 180px;
    }

    @media (max-width: 1440px) {
        padding-left: 170px;
        padding-right: 170px;
    }

    @media (max-width: 1366px) {
        padding-left: 150px;
        padding-right: 150px;
    }

    @media (max-width: 1280px) {
        padding-left: 130px;
        padding-right: 130px;
    }

    @media (max-width: 1024px) {
        padding-left: 110px;
        padding-right: 110px;
    }

    @media (max-width: 992px) {
        padding-left: 95px;
        padding-right: 95px;
    }

    @media (max-width: 768px) {
        padding-left: 80px;
        padding-right: 80px;
    }

    @media (max-width: 600px) {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.btn {
    padding: 15px 30px;
    border-radius: 10px;

    &:focus {
        box-shadow: none;
    }

    @media (max-width: 1024px) {
        padding: 12px 25px;
        font-size: 13px;
    }

    @media (max-width: 600px) {
        padding: 5px 14px;
        border-radius: 5px;
    }

    &-gradient {
        background: $gradient;
        color: $white;
        font-size: 15px;
        line-height: 19px;
        font-weight: 500;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
        transition: all .3s ease;

        @media (max-width: 1024px) {
            font-size: 13px;
            line-height: 17px;
        }

        @media (max-width: 600px) {
            font-size: 10px;
            line-height: 15px;
        }

        &:hover {
            color: $primary;
            border: 1px solid $primary;
            background: none;
        }
    }

    &-outline {
        border: 1px solid $primary;
        color: $primary;
        font-size: 15px;
        line-height: 19px;
        font-weight: 500;
        transition: all .3s ease;

        @media (max-width: 1024px) {
            font-size: 13px;
            line-height: 17px;
        }

        @media (max-width: 600px) {
            font-size: 10px;
            line-height: 15px;
        }

        &:hover {
            background: $primary;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
            color: $white;
            background: $gradient;
            border: 1px solid transparent;
        }
    }
}

.section-title {
    font-size: 38px;
    line-height: 42px;
    margin-bottom: 15px;
    text-align: center;

    @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 38px;
    }

    @media (max-width: 600px) {
        font-size: 28px;
        line-height: 34px;
    }

    span {
        color: $primary;
    }
}

.section-desc {
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    width: 35%;
    margin-bottom: 60px;

    @media (max-width: 1366px) {
        width: 47%;
    }

    @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 19px;
    }

    @media (max-width: 820px) {
        width: 60%;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
}

.error { 
    color: #fa5b5b;
    margin-bottom: 0.5rem;
}
.swal-modal{
    background-color: #34343C;
    transition: opacity .15s linear;
    pointer-events: none;
    .swal-text{
        color : #fff
    }
}

.spinner{
    width: 100%;
    height: 100%;
}
.spinner > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.leading{
    height: 25px;
}

.onvLogo{
    width: 200px;
    height: auto;
    object-fit: cover;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.disabled{
    pointer-events: none;
    // opacity: 0.1;
    color: var(--disabled-color) !important;
}

.no-data{
    font-size: 20px;
    width: fit-content;
    margin: 3rem auto;

}

.pagination-wrap {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pagination-btn {
    padding: 10px;
    color: #FFF;
    border-radius: 5px;
    margin: 0 5px;

    &:hover {
        border: 1px solid rgba(255, 255, 255, 0.5);
        color: $primary;
    }
}

.dashboard-wrap.active {
    .pagination-wrap {
        @media (max-width: 560px) {
            flex-direction: column;
        }
    }
}

.pointer{
    cursor: pointer;
}

.deposit-info-table {
    margin-top: 30px;

    thead {
        background: #E6E9F2;
        tr {
            background: rgba(117, 119, 118, 0.3);
            border-radius: 10px;
            overflow: hidden;

            th {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: rgba(255, 255, 255, 0.8);
                padding: 10px 15px;

                @media (max-width: 600px) {
                    font-size: 11px;
                    line-height: 15px;
                }

                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 26px 6px 6px 6px;
                vertical-align: middle;
                color: var(--table-font-color);
                .btn {
                    padding: 8px 15px;
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 400;

                    @media (max-width: 600px) {
                        font-size: 11px;
                        line-height: 15px;
                        padding: 6px 12px;
                    }
                }
            }
        }
    }
}

.transaction-load{
    width: 100%;
    height: 100%;
    position: absolute;
    background: #efe4e429;
    pointer-events: none;
    z-index: 999;
    div{
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

.pointer-event-none{
    pointer-events: none;
}

._table-contant{
    background: var(--table-color);
    padding: 18px;
}

.tooltipIcon {
    color: var(--title-primary) !important;
}

.fa-circle-info {
    color: var(--title-primary) !important;
}