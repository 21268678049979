@import '../../../variables';

.onboard-header {
    width: 100%;
    padding: 17px 34px;
    position: absolute;
    z-index: 1;
}

.btn-wallet {
    border: 1px solid $primary;
    color: $primary;
    transition: all .3s ease;
    font-weight: 600;

    &:hover {
        background: $primary;
        color: $secondary;
    }
 }