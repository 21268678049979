.modal {
 display: block !important;
 pointer-events: none;
 background: #00000057;
 z-index: 2;
 height: 100%;
 width: 100%;

 .modal-content {
  padding: 1rem 5px 2rem 5px;
  background: #fff;
  justify-content: center;

  .hr{
   color: #fff;
  }
  .wrapper {
   width: 100%;
   height: 100%;
   // display: flex;
   // justify-content: space-between !important;
   // text-align: right !important;

   .spinner-border {
    width: 10rem;
    height: 10rem;
   }

   img {
    margin-bottom: 2rem;
   }

   p {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
   }

   .img-wrap {
    width: 100%;
    height: 100%;

   }

   .meta-button {
    padding: 28px 32px 28px 32px;
    cursor: pointer;
   }

   .meta-connect {
    background-color: #2D2A3D;
    // width: 100%;
    border-radius: 8px;
    padding: 10px 0px 10px 0px;
   }

   .meta-logo {
    width: 25px;
    padding: 12px 0px 12px 0px;
    margin-top: auto;
    margin-bottom: auto;
   }

   .meta-logo-arrow {
    width: 8px;
    margin-top: auto;
    margin-bottom: auto;
   }

   h4 {
    margin-top: 2rem;
   }

   .connect-rinkeby {
    margin-bottom: 0;
   }

   ._link {
    a {
     color: #E85F14;
    }
   }
  }
 }

 @media (min-width: 576px) {
  .modal-dialog {
   max-width: 600px;
   ;
   margin: 1.75rem auto;
  }
 }



}

.closeButton {
 display: flex;
 justify-content: end;
 cursor: pointer;
 padding-right: 20px;

 p {
  color: #E85F14;
 }
}

.ConnectWallet {
 border: 1px solid rgba(248, 94, 17, 1);
 padding: 10px;
 background: #E85F14;
 width: 100%;
 color: #fff;
 border-radius: 20px;
 box-shadow: 6px 6px 11px 10px #0000000D;
}

.claimToken {
 border: 1px solid rgba(248, 94, 17, 1);
 padding: 10px;
 background: none;
 width: 100%;
 color: rgba(248, 94, 17, 1);
 border-radius: 20px;
}

.claimToken:hover {
 border: 1px solid rgb(248, 153, 105);
 padding: 10px;
 background: rgb(248, 153, 105);
 width: 100%;
 color: rgb(109, 40, 6);
 border-radius: 20px;
 transition: all 0.8s ease;
}

.modalBody {
 padding: 10px;
 text-align: center;

 p {
  font-weight: 600;
 }
}

.Follow {
 padding: 10px;
 width: 100%;
}

.followfSm {
 // display: flex;
 // justify-content: space-between;
 align-items: center;
 cursor: pointer;
 padding: 15px 0px 15px 0px;

 .fb {
  display: flex;
  justify-content: space-around;

  .followtext {
   font-weight: 700;
   font-size: 18px;
   color: black;
  }

  .followicon {
   padding-left: 5px;
  }
 }

 .inputfieldSm {
  width: 100%;
  padding: 14px;
  border-radius: 28px;
  border: 1px solid #e85f14;
  outline: none;
 }
}

.Sminput {
 // display: flex;

 .inputVal{
  margin-bottom: 1rem;
 }


 .subbtn {
  padding: 10px;
  width: 100%;
  border-radius: 26px;
  color: #fff;
  border: 1px solid #e85f14;
  background: #e85f14;
 }
}


.PostSocial {
 margin-top: 50px;

 hr {
  margin: 0px !important;
 }
}

.postTitle {
 display: flex;
 justify-content: space-between;

 .PostTitleHead {
  p {
   font-weight: 600;
  }

 }

 .btnDrop {
  font-size: 32px;
  transition: all .3s ease;
 }

 .btnUp {
  transition: all .3s ease;
  transform: rotate(180deg);
  font-size: 32px;
 }
}

.followfSmShare {
 transition: opacity 1s ease;
 display: flex;
 justify-content: space-between;
 align-items: center;
 cursor: pointer;
 padding: 15px 0px 15px 0px;

 .fb {
  display: flex;
  justify-content: space-around;

  .followtext {
   font-weight: 700;
   font-size: 18px;
   color: black;
  }

  .followicon {
   padding-left: 5px;
  }
 }

 // .inputfieldSm {

 //  padding: 5px;
 //  border-radius: 10px;
 //  border: 1px solid rgb(248, 153, 105);
 //  outline: none;
 // }
}

.followfSmShareHide {
 display: none;
}