.icon {
    &-box {
        width: 100%;
        padding: 60px 25px 25px;
        background: url(../../../assets/banner/feature-bg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 30px;
    }

    &-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
    }

    &-title {
        font-size: 22px;
        line-height: 26px;
        font-weight: 500;

        @media (max-width: 1024px) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &-box-content {
        color: rgba(255, 255, 255, 0.8);
        margin-top: 15px;
    }
}