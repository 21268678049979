.dark-theme {
    --card-background: #1F1C2B;
    --title-primary: #fff;
    --title-lable: #fff;
    --card-body-name: #84849D;
    --input-field-display: #2D2A3D;
    --confirm-button-color: #E85F14;
    --confirm-button-border: #E85F14;
    --input-background: #2D2A3D;
  }
  
  .light-theme {
    --card-background: #fff;
    --title-primary: #1E1E1E;
    --title-lable: #9EA0A4;
    --card-body-name: #848484;
    --input-field-display: #F8F9FF;
    --confirm-button-color: #E85F14;
    --confirm-button-border: #E85F14;
    --input-background: #F3F3F3;
  }

.input-group-wrap {
    padding: 12px;
    border: 1px solid rgba($color: var(--input-background), $alpha: 0.5);
    border-radius: 10px;
    margin-bottom: 15px;
    height: 100%;
    background-color: var(--input-background);


    @media (max-width: 600px) {
        width: 100%;
        border: none;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
    }

   

    .value-status {
        margin-right: 0;
    }

    input {
        font-size: 14px;
        line-height: 17px;
        color: #CFCFCF;
        
        @media (max-width: 600px) {
            width: 100%;

            
        }
    }
}

.stake-padding {
    padding: 0px 80px;
    @media (max-width: 1130px) {
        padding: 0px;

        
    }
}

.label {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 12px;
    color: var(--title-lable);
    margin-top: 20px;
    margin-right: 60%;
    @media (max-width: 560px) {
        margin-right: 74%;
        }
    @media (max-width: 390px) {
        font-size: 12px;
        }
    span {
        color: #FF0000;
    }
}

.stack-details {
    .deposite-detail-wrap {
        margin-bottom: 25px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.estimation {
    &-wrap {
        .dashboard-card {
            padding: 0;
        }
    }

    &-card {
        padding: 20px 30px;
    }
}

.estimate-range {
    width: 100%;
}

.value-selector-wrap {
    margin-bottom: 15px;

    .label {
        span {
            &.value-showcase {
                font-size: 12px;
                line-height: 14px;
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }
}

.min-max-value {
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.8);
}

.estimated-value-wrap {
    padding: 23px 18px;
    background: rgba(255, 255, 255, 0.03);
    backdrop-filter: blur(79.7637px);
    border-radius: 30px;

    @media (max-width: 1440px) {
        border-radius: 20px;
    }
}

.input-wrap {
    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.stack-btn-wrap {
    @media (max-width: 820px) {
        // flex-direction: column;
        align-items: flex-start;

        .btn-outline {
            margin-bottom: 10px;
        }
    }

    @media (max-width: 600px) {
        flex-direction: row;

        .btn-outline {
            margin-bottom: 0px;
        }
    }
}

.btn-gradient-stake{
    border: 2px solid var(--confirm-button-border);
    border-radius: 25px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    transition: all .3s ease;
    color: var(--title-primary);
}

.btn-stake{
    padding: 15px 25px;
    border: 1px solid var(--confirm-button-border);
    background: var(--confirm-button-color);
    color: #FFF;
    border-radius: 25px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    transition: all .3s ease;
    justify-content: center;
}

.btn-redeem{
    cursor: pointer;
    margin-bottom: 0;
    width: 100%;
    padding: 15px 25px;
    color: var(--title-primary);
    border: 2px solid var(--confirm-button-border);
    border-radius: 25px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    transition: all .3s ease;
    justify-content: center;
}

.title-white{
    // align-self: center;
    font-size: 24px;

    @media (max-width: 420px) {
        font-size: 16px;
        line-height: 16px;
        margin-top: 20px;
    }
}