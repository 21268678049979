@import '../../../variables';

.dark-theme {
    --bg-color:#2D2A3D;
   --text-color : #A1A1A1;
   --text-second-color : #ffff;
   --box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
  }
  
  .light-theme {
    --bg-color:#fff;
    --text-color : #A1A1A1;
    --text-second-color : #1E1E1E;
    --box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
.parent-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    background-color: var(--bg-color);
    padding: 10px 20px 10px 20px;
}

.child1-div p:nth-child(1){
    color: #E85F14;
    font-size: 16px;
    margin-bottom: 10px;
}
.child1-div{
    p{
        color: var(--text-color)
    }
}
.grandchild1{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 48px;
    margin-bottom: 10px;
    p{
        color: #A1A1A1;
    }
}
.child2-div{
    display: flex;
    gap: 20px;
}
.child2-btn{
    background-color: #2D2A3D;
    border: none;
    padding: 16px 20px;
    border-radius: 50px;
    color: #A1A1A1;
    display: flex;
    gap: 10px;
    background-color: var(--bg-color);
    box-shadow: var(--box-shadow);
}
.second-parent{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: auto auto;
    gap: 10px;
}
.child-1, .child-2, .child-3 {
    background-color: var(--bg-color);
}
.child-1{
    grid-row: span 2;
    padding: 50px;
}
.child-1-head{
    font-size: 14px;
    font-weight: 500;
    color: #A1A1A1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}
.child-1-head p:nth-child(2){
    margin-right: 40px;
}
.child-2-head{
    margin-bottom: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.child-2-head-last{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.child-2-head-1{
    display: flex;
    align-items: center;
    gap: 10px
}
.child-2-head-2{
    display: flex;
    align-items: center;
    gap: 20px
}
.child-2-head-2 p:nth-child(1){
    margin-right: 30px;
    color: var(--text-second-color);
}
.child-2-head-2-btn{
    color: #A1A1A1;
    font-size: 26px;
    padding: 16px;
    background-color: var(--bg-color);
    border: none;
    border-radius: 60%;
    box-shadow: var(--box-shadow)
}
.child-2-head-2-btn:hover{
    cursor: pointer;
    color: #ffff;
    box-shadow: rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;
}
.child-name p:nth-child(1){
    font-size: 18px;
    color: var(--text-second-color);
}
.child-name p:nth-child(2){
    color: #A1A1A1;
    font-size: 16px;
}

.child-2{
    padding: 50px;
}

.balance{
    font-size:14px;
    font-weight: 500;
    color: #A1A1A1;
    margin-bottom: 44px;
}
.balance-child{
    margin-top: 20px;
    font-size: 18px;
    width: 22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
hr{
    color: #A1A1A1;    
    margin-bottom: 44px;
}

.parent-borrow {
    display: flex;
    justify-content: space-between;
  }
  
  .borrow p:nth-child(1),
  .supply p:nth-child(1) {
    font-size: 14px;
    color: #a1a1a1;
    margin-bottom: 10px;
  }
  
  .borrow p:nth-child(2),
  .supply p:nth-child(2) {
    font-size: 18px;
    position: relative;
    color: var(--text-second-color);
  }
  
  .borrow p:nth-child(2)::after,
  .supply p:nth-child(2)::after {
    content: attr(data-reflection); /* Reflect the content of the data-reflection attribute */
    position: absolute;
    bottom: -18px; /* Adjust as needed */
    left: 0;
    font-size: 18px; /* Match the font size of the original text */
    color: var(--text-second-color); /* Adjust the reflection color and opacity */
    transform: scaleY(-.1); /* Flip the reflection vertically */
  }
  
  .child-3{
    padding: 50px;
  }
  .child-3-title{
    font-size: 14px;
    color: #A1A1A1;
    margin-bottom: 44px;
}
.positon{
    display: flex;
    justify-content: space-between;
    margin-bottom: 31px;
}
.positon-last{
    display: flex    ;
    justify-content: space-between;
    
}
.positon p:nth-child(1), .positon-last p:nth-child(1){
    font-size: 18px;
    font-weight: 500;
    color: var(--text-second-color);
}
.positon p:nth-child(2), .positon-last p:nth-child(2){
    font-size: 18px;
    color: #A1A1A1;
    font-weight: 500;
}



















// =============================== Quary ================================

@media screen and (max-width: 1600px) {
    .second-parent{
        grid-template-columns: repeat(1,1fr);
    }
}
@media screen and (max-width: 1120px) {
    
}
@media screen and (max-width: 768px) {
    
}
@media screen and (max-width: 452px) {
    .child-1-head p:nth-child(2){
        display: none;    
    }
    .child-1-head {
        justify-content: center; 
    }
    .child-1{
        max-width: 100%;
    }
    .child-2-head, .child-2-head-last{
        flex-direction: column;
    }
    .child-2-head-1{
        align-self: flex-start;
        
    }
    .child-name{
        line-height: 20px;
    }
    .child-2-head-2{
        margin-top: 20px;
    }
    .child-2-head-2 p:nth-child(1){
        margin-right: 10px;
        font-size: 20px;
    }
    .parent-div{
        flex-direction: column;
    }
    .child2-div{
        margin-top: 10px;
        width: 80%;
        flex-direction: column;
    }
    .positon, .positon-last{
        line-height: 20px;
    }
}

@media screen and (max-width: 352px) {
    .child-1, .child-2, .child-3{
        max-width: 74%;
    }
    .child-2-head-1{
        align-self: flex-start;
    }
}