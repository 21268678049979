@import '../../../variables';

.header {
    padding-top: 35px;
    padding-bottom: 35px;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 9;

    @media (max-width: 600px) {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

.menu-link {
    margin-left: 16px;
    margin-right: 16px;
    color: #fff;

    @media (max-width: 600px) {
        margin-left: 10px;
        margin-right: 10px;
    }

    &.btn {
        margin-right: 0;
    }
}

.logo {
    img {
        @media (max-width: 600px) {
            width: 70px;
        }
    }
}