@import '../../../variables';

.home-banner-wrap {
    height: 100vh;
    background: url(../../../assets/banner/banner-bg.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 250px;

    @media (max-width: 1280px) {
        padding-top: 200px;
        height: auto;
    }

    @media (max-width: 768px) {
        padding-top: 160px;
    }

    @media (max-width: 600px) {
        padding-top: 115px;
    }
}

.banner {
    &-title {
        font-size: 64px;
        color: #FFF;
        line-height: 76px;
        font-weight: 600;
        margin-bottom: 20px;

        @media (max-width: 1280px) {
            font-size: 60px;
            line-height: 72px;
        }

        @media (max-width: 1024px) {
            font-size: 54px;
            line-height: 66px;
        }

        @media (max-width: 1024px) {
            font-size: 50px;
            line-height: 62px;
        }

        @media (max-width: 768px) {
            font-size: 40px;
            line-height: 50px;
        }

        @media (max-width: 600px) {
            font-size: 35px;
            line-height: 45px;
        }

        span {
            color: $primary;
        }

        &-wrap {
            width: 40%;

            @media (max-width: 1480px) {
                width: 45%;
            }

            @media (max-width: 1200px) {
                width: 50%;
            }

            @media (max-width: 820px) {
                width: 60%;
            }

            @media (max-width: 600px) {
                width: 100%;
            }
        }
    }

    &-text {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 38px;

        @media (max-width: 1280px) {
            font-size: 22px;
            line-height: 26px;
        }

        @media (max-width: 1024px) {
            font-size: 18px;
            line-height: 22px;
        }

        @media (max-width: 768px) {
            font-size: 15px;
            line-height: 18px;
        }
    }

    &-btn-wrap {
        .btn {
            margin-right: 30px;
        }
    }
}

.counter {
    &-wrap {
        padding-top: 125px;
        padding-bottom: 125px;

        @media (max-width:768px) {
            padding-bottom: 80px;
        }
    }

    &-box-wrap {
        background: #34343C;
        box-shadow: -3px -3px 12px #515159,
            6px 5px 5px #262729,
            inset 6px 6px 3px rgba(38, 39, 41, 0.29),
            inset -8px -6px 7px rgba(255, 255, 255, 0.29);
        border-radius: 40px;
        padding: 25px;

        @media (max-width: 600px) {
            padding: 20px;
            border-radius: 25px;
        }
    }

    &-content-wrap {
        background: url(../../../assets/banner/counter-bg.png);
        padding: 55px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 30px;

        @media (max-width: 1024px) {
            padding: 45px;
        }

        @media (max-width: 600px) {
            padding: 35px;
            border-radius: 15px;
        }
    }

    &-title {
        font-size: 22px;
        line-height: 28px;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 18px;
            line-height: 24px;
        }

        @media (max-width: 600px) {
            font-size: 15px;
            line-height: 22px;
        }
    }


    &-value {
        font-size: 64px;
        color: $primary;
        line-height: 75px;
        font-weight: 600;
        margin-top: 15px;

        @media (max-width: 1024px) {
            font-size: 60px;
            line-height: 71px;
        }

        @media (max-width: 992px) {
            font-size: 55px;
            line-height: 66px;
        }

        @media (max-width: 600px) {
            font-size: 35px;
            line-height: 55px;
        }
    }
}

.feature-wrap {
    padding-top: 80px;
    padding-bottom: 80px;
}

.accordion-button {
    background-color: transparent;
    color: #FFF;
    font-size: 22px;
    line-height: 26px;
    padding: 30px 0;

    @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 24px;
    }

    @media (max-width: 820px) {
        font-size: 14px;
        line-height: 18px;
    }

    &:not(.collapsed) {
        background: transparent;
        box-shadow: none;

        &::after {
            background-image: url(../../../assets/svg/down-arrow.svg);
        }
    }

    &::after {
        background-image: url(../../../assets/svg/down-arrow.svg);
    }

    &:focus {
        box-shadow: none;
    }
}

.accordion-item {
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid $primary;
    border-radius: 0;
}

.accordion-body {
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    line-height: 22px;
    padding: 0 0 30px;

    @media (max-width: 1366px) {
        font-size: 15px;
        line-height: 19px;
    }

    @media (max-width: 1024px) {
        font-size: 13px;
        line-height: 17px;
    }
}

.faq-section {
    padding-top: 100px;
    padding-bottom: 200px;

    @media (max-width: 768px) {
        padding-top: 75px;
        padding-bottom: 100px;
    }

    @media (max-width: 600px) {
        padding-bottom: 0px;
    }
}