@import '../../../variables';

.dark-theme {
    --font-color: white;
    --table-head: #2D2A3D;
    --table-head-font: rgba(255, 255, 255, 0.8);
}

.light-theme {
    --font-color: #E85F14;
    --table-head: #F3F3F3;
    --table-head-font: #848484;
}

.pagination-wrap {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    width: 100%;
    padding-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pagination-btn {
    padding: 10px;
    color: #FFF;
    border-radius: 5px;
    margin: 0 5px;

    &:hover {
        border: 1px solid rgba(255, 255, 255, 0.5);
        color: $primary;
    }
}

.dashboard-wrap.active {
    .pagination-wrap {
        @media (max-width: 560px) {
            flex-direction: column;
        }
    }
}



.deposit-info-table {
    margin-top: 30px;

    thead {

        tr {
            border-radius: 18px;
            overflow: hidden;
            position: sticky;
            top: 0;

            th {
                background: var(--table-head);

                p {
                    position: sticky;
                    top: 0;
                    // font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: var(--table-head-font);
                    //padding: 10px 15px;
                }

                @media (max-width: 600px) {
                    font-size: 11px;
                    line-height: 15px;
                }

                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 26px 6px 6px 6px;
                vertical-align: middle;

                .btn {
                    padding: 8px 15px;
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 400;

                    @media (max-width: 600px) {
                        font-size: 11px;
                        line-height: 15px;
                        padding: 6px 12px;
                    }
                }

            }
        }
    }
}

.tableFixHead {
    overflow: auto;
    height: 500px;
    padding-right: 1rem;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #2D2A3D;
}

.tableFixHead {
    .deposit-info-table {
        margin-top: 0px;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

}

/* Just common table stuff. Really. */