@import '../../../variables';

.dark-theme {
    --card-background: #1F1C2B;
    --title-primary: #fff;
    --title-head: #fff;
    --card-body-name: #84849D;
    --card-text-display: #2D2A3D;
    --valt-button-border: #E85F14;
    --button-border: rgba(255, 255, 255, 0.5)
  }
  
  .light-theme {
    --card-background: #fff;
    --title-primary: #1E1E1E;
    --title-head: #E85F14;
    --card-body-name: #848484;
    --card-text-display: #F8F9FF;
    --valt-button-border: #E85F14;
    --button-border: #b0d2f8;
  }
.vault-padding{
    padding: 0px 80px;

    @media (max-width: 1250px) {
        padding: 0px;
        
    }

}

.inputNumber {
    @media (max-width: 450px) {
        border: 1px solid #9EA0A4;
    }
}

.vault-card {
    width: 100%;
    margin-bottom: 25px;

    // @media (max-width: 1366px) {
    //     width: 675px;
    // }

    // @media (max-width: 1024px) {
    //     width: 580px;
    // }

    // @media (max-width: 820px) {
    //     width: 515px;
    // }

    // @media (max-width: 768px) {
    //     width: 500px;
    // }

    @media (max-width: 600px) {
        width: 100%;
        text-align: center;

        .card-body-value {
            text-align: center !important;
        }
    }

    .card-body-name {
        margin-bottom: 15px;

        @media (max-width: 820px) {
            margin-bottom: 10px;
            font-size: 12px;
        }
    }

    .card-body-namer{
        font-size: 18px;
        color: var(--title-primary);
    }

    .card-body-value {
        font-size: 24px;
        line-height: 30px;

        @media (max-width: 830px) {
                font-size: 21px;
                line-height: 30px;
            }
        @media (max-width: 380px) {
                font-size: 20px;
                line-height: 30px;
            }

        span {
            position: relative;

            &::before {
                position: absolute;
                content: '';
                width: 10px;
                height: 10px;
                background: #51FE42;
                border-radius: 50%;
                left: -20px;
                top: 25%;
            }
        }
    }
}

.tcr-ratio{
    white-space: nowrap;
}

.close-btn{
    border: none;
    cursor: pointer;
}

.title-primary {
    color: var(--title-head);
    font-size: 16px;
    @media (max-width: 830px) {
            font-size: 16px;
        }
    @media (max-width: 380px) {
            font-size: 13px !important;
            text-align: left;
        }
    svg {
        color: $primary;
    }
}

.text-start {
    font-size: 24px;
}

.ration-progress {
    width: 55%;
    align-self: center;
    @media (max-width: 1040px) {
        width: 74%;
       // padding: 0 85px;
    }
    @media (max-width: 770px) {
        width: 80%;
       // padding: 0 85px;
    }
    @media (max-width: 740px) {
        width: 60%;
       // padding: 0 85px;
    }
    @media (max-width: 720px) {
        width: 35%;
       // padding: 0 85px;
    }
    @media (max-width: 600px) {
        width: 28%;
        padding: 0 85px;
    }
    @media (max-width: 387px) {
        width: 37%;
        padding: 0 85px;
    }
}

.progress-bar{
    align-self: center;
    background: none;
}

.card-text-display {
    align-self: center;
    padding: 64px;
    background: var(--card-text-display);
    border-radius: 12px;
    @media (max-width: 387px) {
        padding: 28px;
        }
}

.card-btn-vault{
    margin-bottom: 0;
    width: 100%;
    padding: 15px 25px;
    border: 2px solid var(--valt-button-border);
    border-radius: 25px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    transition: all .3s ease;

    @media (max-width: 820px) {
        margin-right: 5px;
        padding: 6px;
    }

    @media (max-width: 400px) {
        padding: 5px;
    }

    &:hover {
        background: rgba(255, 255, 255, 0.5);
        border-color: var(--button-border);
    }

    img {
        max-width: 16px;
        max-height: 16px;
        margin-right: 10px;

    @media (max-width: 1200px) {
        max-width: 14px;
        max-height: 14px;
        margin-right: 5px;
    }

    @media (max-width: 1024px) {
        max-width: 12px;
        max-height: 12px;
    }

    @media (max-width: 400px) {
        max-width: 10px;
        max-height: 10px;
        }
    }

    p {
        font-size: 16px;
        line-height: 19px;
        color: var(--title-primary);

        @media (max-width: 1200px) {
            font-size: 14px;
            line-height: 18px;
        }

    @media (max-width: 1024px) {
            font-size: 12px;
            line-height: 16px;
        }

    @media (max-width: 400px) {
            font-size: 8px;
            line-height: 12px;
        }
    }

}
.centerd-text{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

// .vault-navigation-page{

// }

.nav-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;

    @media (max-width: 600px) {
        justify-content: center;
    }

    .nav-link {
        margin-bottom: -1.5px;
    }
}

.nav-item {
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;

    color: #FFFFFF;

    @media (max-width: 820px) {
        font-size: 12px;
    }

    @media (max-width: 768px) {
        font-size: 10px;
        line-height: 14px;
    }

    @media (max-width: 600px) {
        font-size: 8px;
        line-height: 12px;
    }

    .nav-link {
        padding: 10px 40px;
        border-top: none;
        border-left: none;
        border-right: none;
        color: var(--title-primary);

        @media (max-width: 820px) {
            padding: 8px 20px;
        }

        @media (max-width: 600px) {
            padding: 10px 15px;
        }

        &.active, &:hover {
            color: var(--title-primary);
            background-color: transparent;
            border-bottom: 2px solid $primary;
            border-top: none;
            border-left: none;
            border-right: none;
            font-weight: 500;
        }
    }
}

.close-box {
    svg {
        @media (max-width: 820px) {
            width: 15px;
        }
    }
}

.close{
    fill: var(--title-primary);
}

.close-text{
    color: var(--title-primary);
}

.close-des{
    border: none;
}

