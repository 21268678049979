@import '../../../variables';

.dark-theme {
    --icon-color: #E85F14;
    --font-color: #1E1E1E;
    --background-color: #15141A;
    --font-color-header: white;
    --side-button: #E85F14;
    --nav-background: #1F1C2B;
    --hover-font: #B2B4BF;
    --active-font: #1E1E1E;
    --active-icon: #E85F14;
    --dash-header: #1F1C2B;
    --button-add-bg: #1F1C2B;
    --button-add-txt: white;
}

.light-theme {
    --icon-color: #E85F14;
    --font-color: white;
    --background-color: #E6E9F2;
    --font-color-header: #1E1E1E;
    --side-button: #E85F14;
    --nav-background: #FFF;
    --hover-font: #B2B4BF;
    --active-font: #1E1E1E;
    --active-icon: #E85F14;
    --dash-header: #E6E9F2;
    --button-add-bg: #E6E9F2;
    --button-add-txt: #1E1E1E;
}


.barTop {
    fill: var(--side-button);
}

.bar {
    fill: var(--side-button);
}

.barBottom {
    fill: var(--side-button);
}

.dashboard {
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 34px;
        width: 100%;
        background: var(--background-color);

        @media (max-width: 768px) {
            width: auto;
        }

        @media (max-width: 600px) {
            flex-direction: column;

            .logo {
                width: 100px;
            }
        }
    }

    &-title {
        display: flex;

        .title {
            font-size: 28px;
            font-weight: 600;
            line-height: normal;
            color: var(--font-color-header);
            margin-left: 0px;
            transform: translate3d(10px, 0, 0);
            transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);

            @media (max-width: 1080px) {
                transform: translate3d(20px, 0, 0);
            }

            @media (max-width: 820px) {
                transform: translate3d(76px, 0, 0);
            }

            @media (max-width: 720px) {
                transform: translate3d(76px, 0, 0);
            }

            @media (max-width: 640px) {
                // transform: translate3d(76px, 0, 0);
                transform: translate3d(0px, 0, 0);
            }

            @media (max-width: 420px) {
                // transform: translate3d(152px, 0, 0);
                transform: translate3d(0px, 0, 0);
                font-size: 20px;
            }

            @media (max-width: 320px) {
                // transform: translate3d(0px, 0, 0);
                transform: translate3d(86px, 0, 0);
                // font-size: 19px;
                font-size: 14px;
            }

        }
    }

    &-layout {
        position: absolute;
        background: #1F1C2B;
        width: 100%;

        @media (max-width: 600px) {
            position: absolute;
        }

        &.active {
            .dashboard-sidebar {
                // transform: translate3d(0px, 0, 0);
                transform: translate3d(-250px, 0, 0);
                transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);

                @media (max-width: 820px) {
                    // transform: translate3d(0px, 0, 0);
                    transform: translate3d(-200px, 0, 0);
                }

                @media (max-width: 600px) {
                    // transform: translate3d(0px, 0, 0);
                    transform: translate3d(-220px, 0, 0);
                }
            }

            .dashboard-title {
                .title {
                    transform: translate3d(-220px, 0, 0);
                    transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);

                    @media (max-width: 820px) {
                        transform: translate3d(-92px, 0, 0);
                    }

                    @media (max-width: 600px) {
                        font-size: 19px;
                        justify-content: center;
                        align-items: center;
                        align-self: center;
                        transform: translate3d(-60px, 0, 0);
                    }

                    @media (max-width: 320px) {
                        font-size: 19px;
                        justify-content: center;
                        align-items: center;
                        align-self: center;
                        transform: translate3d(-60px, 0, 0);
                    }
                }
            }

            .sidebar-btn {
                transform: translate3d(-225px, 0, 0) rotate(180deg) translateX(0px);
                transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);
                // animation: moveRound 1s linear 2s;


                .bar {
                    transform: translate3d(-2px, 0, 0);
                }

                .barTop {
                    transform: translate3d(9px, 0, 0) rotate(-45deg) translateX(-15px);
                }

                .barBottom {
                    transform: translate3d(19px, 0, 0) rotate(45deg) translateX(-4px);
                    margin-top: 20px;
                }

                @media (max-width: 920px) {
                    transform: translate3d(-240px, 0, 0) rotate(180deg) translateX(0px);
                }

                @media (max-width: 820px) {
                    transform: translate3d(-135px, 0, 0) rotate(180deg) translateX(0px);
                }

                @media (max-width: 600px) {
                    transform: translate3d(-220px, 0, 0) rotate(180deg) translateX(0px);
                }

                @media (max-width: 320px) {
                    transform: translate3d(-200px, 0, 0) rotate(180deg) translateX(0px);
                }
            }
        }
    }

    &-sidebar {
        padding: 10px 0 30px 15px;
        position: fixed;
        background: var(--nav-background);
        height: calc(100% - 0px);
        width: 250px;
        top: 0px;
        display: grid;
        align-content: start;
        transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);
        z-index: 10;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        @media (max-width: 820px) {
            width: 200px;
        }

        @media (max-width: 768px) {
            top: 0px;
            // top: 70px;
            height: calc(100% - 0px);
            // height: calc(100% - 70px);
        }

        @media (max-width: 600px) {
            top: 0px;
            // top: 116px;
            width: 225px;
            transform: translate3d(0px, 0, 0);
            // transform: translate3d(-140px, 0, 0);
            height: calc(100% - 0px);
            // height: calc(100% - 116px);
        }
    }

    &-sidebar::-webkit-scrollbar {
        display: none;
    }

    &-nav {
        padding: 16px 20px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        position: relative;
        right: 0;
        height: fit-content;
        display: flex;
        align-items: center;
        color: #84849D;
        transition: all .3s ease;
        margin-bottom: 5px;

        @media (max-width: 768px) {
            padding: 14px 18px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        @media (max-width: 600px) {
            padding: 11px 12px;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &.active,
        &:hover {
            //background: rgba(255, 255, 255, 0.24);
            color: var(--font-color);

            .icon {
                &-white {
                    display: none;
                    margin-right: 11px;
                    transition: all .3s ease;
                }

                &-fill {
                    display: block;
                    margin-right: 11px;
                    transition: all .3s ease;
                }
            }
        }

        .icon {
            &-white {
                display: block;
                margin-right: 11px;
            }

            &-fill {
                display: none;
            }
        }
    }
}

.sidebar-btn {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px;
    transition: all .3s ease;
    transition: transform .45s cubic-bezier(0.77, 0, 0.175, 1);

    @media (max-width: 925px) {
        left: 15px;
    }

    @media (max-width: 820px) {
        left: 20px;

        svg {
            width: 14px;
        }
    }

    @media (max-width: 768px) {
        padding: 7px;
    }

    @media (max-width: 600px) {
        left: 154px;
    }

    @media (max-width: 420px) {
        left: 160px;
    }

    @media (max-width: 320px) {
        left: 164px;
    }

    // &:hover {
    // background: $primary;
    // }
}

.currency-btn {
    // background: linear-gradient(102.23deg, rgba(66, 251, 254, 0.3) 0%, rgba(34, 108, 208, 0.3) 152.98%);
    border-radius: 15px;
    display: flex;
    align-items: center;

    .currency-wrap {
        padding: 10px;

        @media (max-width: 768px) {
            padding: 5px 10px;
        }
    }

    p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
        color: #FFF;

        @media (max-width: 768px) {
            font-size: 12px;
            line-height: 14px;
        }

        @media (max-width: 600px) {
            font-size: 8px;
            line-height: 12px;
        }
    }
}

.wallet-currency-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--button-add-bg);
    border: 2px solid var(--side-button);
    // background: linear-gradient(102.23deg, rgba(66, 251, 254, 0.3) 0%, rgba(34, 108, 208, 0.3) 152.98%);
    border-radius: 25px;
    padding: 10px;

    @media (max-width: 768px) {
        padding: 5px;
    }

    .profile-img {
        width: 25px;
        height: 25px;
        // border-radius: 50%;
        overflow: hidden;
        margin-left: 15px;

        .imageLogo {
            margin-left: 70%;
            width: 30px;
            color: var(--button-add-txt);
        }
    }

    p {
        color: var(--button-add-txt);
    }
}

.copy-address {
    position: relative;

    button {
        opacity: 0;
        cursor: pointer;
    }

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        cursor: pointer;
    }
}

.header-rightside,
.header-leftside {
    display: flex;
    align-items: center;

    @media (max-width: 600px) {
        justify-content: space-between;
        width: 100%;
    }

    .btn-wallet {
        font-size: 14px;
        line-height: 18px;
        padding: 7px;
        margin-right: 10px;
        border-radius: 5px;
        color: #FFF;

        @media (max-width: 768px) {
            font-size: 12px;
            line-height: 16px;
        }

        @media (max-width: 600px) {
            font-size: 7px;
            line-height: 11px;
        }
    }
}

.header-leftside {
    margin-left: 15%;

    @media (max-width: 1600px) {
        margin-left: 20%;
    }

    @media (max-width: 1280px) {
        margin-left: 22%;
    }

    @media (max-width: 1024px) {
        margin-left: 25%;
    }

    @media (max-width: 820px) {
        margin-left: 28%;
    }

    @media (max-width: 600px) {
        margin-left: 20%;
    }
}

.header-rightside {
    @media (max-width: 600px) {
        justify-content: center;
        margin-top: 15px;
    }
}

.dashboard-icon {
    padding: 10px 0px 60px 0px;
}

// .sidebar-social-wrap {
//     padding: 10px 27px;
//     background: rgba(31, 28, 43, 0.6);
//     box-shadow: 0.9px 0.9px 6.9px rgba(255, 255, 255, 0.05),
//     inset 0.9px 0.9px 3.9px rgba(0, 0, 0, 0.6);
//     border-radius: 15px;
//     position: absolute;
//     bottom: 28px;
//     left: 27px;

//     @media (max-width: 1600px) {
//         left: 30px;
//     }

//     @media (max-width: 1280px) {
//         left: 42px;
//         padding: 10px 20px;
//         border-radius: 12px;
//     }

//     @media (max-width: 1024px) {
//         left: 48px;
//     }

//     @media (max-width: 820px) {
//         left: 27px;
//     }

//     @media (max-width: 600px) {
//         left: 16px;
//         padding: 7px 15px;
//         border-radius: 8px;
//     }

//     a {
//         width: 25px;
//         height: 25px;
//         margin: 0 7px;

//         @media (max-width: 600px) {
//             margin: 0 5px;
//         }

//         &:hover {
//             svg {
//                 color: $primary;
//             }
//         }

//         svg {
//             color: #FFF;
//             transition: all .3s ease;
//         }
//     }
// }

.modal-open {
    pointer-events: none;
}

.modal {
    display: block;
    pointer-events: none;
    background: #d8d8f957 !important;
    z-index: 12;

    .modal-content {
        border: none;
        padding: 2rem 5px 2rem 5px;
        background: #1F1C2B;
        justify-content: center;
        align-items: center;

        .wrapper {
            width: 100%;
            height: 100%;
            text-align: center;
            justify-content: center;
            align-items: center;

            .spinner-border {
                width: 10rem;
                height: 10rem;
            }

            img {
                margin-bottom: 2rem;
                text-align: center;
            }

            p {
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: center;
            }

            .img-wrap {
                width: 100%;
                height: 100%;
                justify-content: center;
                text-align: center;
            }

            .meta-button {
                padding: 28px 32px 28px 32px;
                cursor: pointer;
            }

            .meta-connect {
                background-color: #2D2A3D;
                // width: 100%;
                border-radius: 8px;
                padding: 10px 0px 10px 0px;
            }

            .meta-logo {
                width: 25px;
                padding: 12px 0px 12px 0px;
                margin-top: auto;
                margin-bottom: auto;
            }

            .meta-logo-arrow {
                width: 8px;
                margin-top: auto;
                margin-bottom: auto;
            }

            h4 {
                margin-top: 2rem;
            }

            .connect-rinkeby {
                margin-bottom: 0;
            }

            ._link {
                a {
                    color: #E85F14;
                }
            }
        }
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 500px;
            ;
            margin: 1.75rem auto;
        }
    }

}

.bottom-bar {
    position: absolute;
    top: 92vh;
    left: 25px;
    z-index: 10;
    // background: var(--background-color);

    padding: 5px;

    @media (max-width: 1024px) {
        left: 35px;
        top: 92vh
    }

    @media (max-width: 820px) {
        left: 25px;
        top: 92vh
    }

    @media (max-width: 560px) {
        left: 7px;
        top: 92vh
    }

    @media (max-width: 320px) {
        left: 7px;
        top: 96vh
    }

    @media (max-height: 570px) {
        top: 120vh;
        padding-bottom: 10px;

    }

    p {
        font-size: 12px;
        color: var(--button-add-txt);
        text-align: center;

        @media (max-width: 820px) {
            font-size: 10px;
        }

        img {
            height: 20px;
            margin-left: 5px;
        }
    }
}

.scrollable-container {
    overflow: hidden;
}

.bell {
    // height: 14px;
    width: 41px;
}

.bell-space {
    padding-left: 10px;
    padding-right: 10px;
}

.notification {
    z-index: 10;
    background-color: yellow;
    margin-top: 4rem;
    margin-left: 58%;
    width: 37rem;
    position: absolute;

    @media (max-width: 720px) {
        margin-left: 26%;
        width: 22rem;
    }

    @media (max-width: 414px) {
        margin-left: 26%;
        width: 22rem;
    }

    @media (max-width: 400px) {
        margin-left: 8%;
        width: 17rem;
    }
}

.arrowDown {
    height: 12px;
    transform: rotate(360deg);
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
}

.chainDisp {
    display: flex;
    justify-content: space-around;

    .chainimg {
        padding: 2px 2px 2px 2px;
        img{
            height: 30px;
        }
    }

    .chainArrow {
        padding: 2px 2px 2px 2px;
        display: flex;
        align-items: center;
    }
}

.act {
    transform: rotate(180deg);
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
}

.outerDrop {
    position: relative;

    .dropDownMenu {
        padding: 10px;
        z-index: 10;
        background-color: var(--background);
        color: var(--text-color);
        margin-top: 4rem;
        margin-left: 75%;
        width: 10rem;
        position: absolute;
        transition: opacity .5s ease;
        box-shadow: 0px 0px 12px 12px rgba(151, 150, 150, 0.2);

        @media (max-width: 1880px) {
            margin-left: 78%;
            width: 10rem;
            margin-top: 5rem;
        }

        @media (max-width: 1760px) {
            margin-left: 72%;
            width: 10rem;
            margin-top: 5rem;
        }

        @media (max-width: 1760px) {
            margin-left: 68%;
            width: 10rem;
            margin-top: 5rem;
        }

        @media (max-width: 1450px) {
            margin-left: 65%;
            width: 10rem;
            margin-top: 5rem;
        }

        @media (max-width: 1300px) {
            margin-left: 60%;
            width: 10rem;
            margin-top: 5rem;
        }

        @media (max-width: 1200px) {
            margin-left: 56%;
            width: 10rem;
            margin-top: 5rem;
        }

        @media (max-width: 1040px) {
            margin-left: 52%;
            width: 10rem;
            margin-top: 5rem;
        }

        @media (max-width: 920px) {
            margin-left: 22%;
            width: 10rem;
            margin-top: 5rem;
        }

        @media (max-width: 780px) {
            margin-left: 16%;
            width: 10rem;
            margin-top: 5rem;
        }

        @media (max-width: 720px) {
            margin-left: 26%;
            width: 10rem;
            margin-top: 5rem;
        }

        @media (max-width: 600px) {
            margin-left: 14%;
            width: 10rem;
            margin-top: 7rem;
        }

        @media (max-width: 450px) {
            margin-left: 8%;
            width: 10rem;
            margin-top: 7rem;
        }

        @media (max-width: 400px) {
            margin-left: 8%;
            width: 10rem;
            margin-top: 7rem;
        }
    }
}

.chainsDiff {
    display: flex;
    padding-bottom: 4px;
    padding-top: 2px;
    border-bottom: 1px solid #4d4d4d;
    cursor: pointer;
    .chainIcon {
        padding: 2px 2px 2px 2px;
    }

    .chainName {
        padding: 2px 2px 2px 2px;
    }
}

.chainsDiff:hover {
    background: #2d2a3c;
}