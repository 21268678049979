@import '../../../variables';

.dashboard-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 0;

    img {
        height: 100%;
        min-width: 100%;

        @media (max-width: 600px) {
            width: auto;
            height: 100%;
        }
    }
}

.wallet {
    &-section {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 600px) {
            padding: 50px;
        }
    }
    &-wrap {
        background: url(../../../assets/banner/wallet-bg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 40px;
        border-radius: 25px;
        position: relative;
        z-index: 2;
        width: fit-content;

        @media (max-width: 600px) {
            padding: 30px;
            border-radius: 20px;
        }
    }

    &-box {
        padding: 20px;
        border: 1px solid $primary;
        border-radius: 10px;
        margin-bottom: 25px;
        transition: all .3s ease;
        width: 100%;

        @media (max-width: 600px) {
            padding: 15px;

            img {
                width: 40px;
            }
        }

        &:hover {
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.23);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-name {
        margin-left: 24px;

        h4 {
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
            color: #FFF;
            text-align: left;

            @media (max-width: 600px) {
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
            }
        }

        p {
            font-size: 16px;
            line-height: 19px;
            margin-top: 5px;
            font-weight: 400;
            color: #FFF;
            text-align: left;

            @media (max-width: 600px) {
                font-size: 12px;
                line-height: 15px;
                font-weight: 300;
            }
        }
    }
}

.overlay-popup {
    width: 100%;
    height: 100%;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 9;
    background: rgba(0, 0, 0, 0.25);
    top: 0;

    @media (max-width: 600px) {
        padding: 50px;
    }
}

.popup {
    &-wrap {
        padding: 25px;
        border-radius: 25px;
        background: #36363F;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.23);
        backdrop-filter: blur(62.9126px);
        width: 600px;

        @media (max-width: 600px) {
            padding: 20px;
            border-radius: 20px;
        }
    }

    &-header {
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: #FFF;

        @media (max-width: 600px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.switch-text {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;

    @media (max-width: 600px) {
        font-size: 13px;
        line-height: 17px;
    }
}

.learn {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    @media (max-width: 600px) {
        font-size: 12px;
        line-height: 12px;
    }
}