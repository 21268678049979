@import '../../../variables';

.footer {
    padding-top: 165px;
    padding-bottom: 68px;
    background: url(../../../assets/banner/footer-bg.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    bottom: 0;

    &-desc {
        margin: 30px 0 36px;
    }

    &-social {
        display: flex;
        justify-content: center;

        &-link {
            margin-right: 21px;

            &:last-child {
                margin-right: 0;
            }

            svg {
                color: #FFF;
                transition: all .3s ease;
            }

            &:hover {
                svg {
                    color: $primary;
                }
            }
        }
    }

    &-links-title {
        font-size: 16px;
        line-height: 20px;
        color: #FFF;
        font-weight: 500;
        margin-bottom: 15px;

        @media (max-width: 820px) {
            font-size: 14px;
            margin-bottom: 8px;
        }
    }

    &-link {
        font-size: 14px;
        line-height: 16px;
        color: $gray1;

        @media (max-width: 1200px) {
            font-size: 12px;
        }

        @media (max-width: 820px) {
            font-size: 11px;
        }

        &-wrap {
            margin-left: 40px;

            @media (max-width: 1024px) {
                margin-left: 25px;

                li {
                    margin-bottom: 5px;
                }
            }

            @media (max-width: 820px) {
                margin-left: 0;
            }
        }
    }
}