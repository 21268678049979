@import '../../../variables';
.dark-theme {
    --font-color: white;
    --table-head: #2D2A3D;
    --table-head-font: rgba(255, 255, 255, 0.8);
  }
  .light-theme {
    --font-color: #000;
    --table-head: #F3F3F3;
    --table-head-font: #848484;
  }
  
.deposit-info-table {
    margin-top: 30px;

    thead {
        background: var(--table-head);
        tr {
            background: var(--table-head);
            border-radius: 10px;
            overflow: hidden;

            th {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                // color: var(--table-head-font);
                padding: 10px 15px;

                @media (max-width: 600px) {
                    font-size: 11px;
                    line-height: 15px;
                }

                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                }

                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                text-align: left;
                padding: 26px 6px 6px 6px;
                vertical-align: middle;
                .btn {
                    margin-right: 50%;
                    padding: 8px 15px;
                    font-size: 14px;
                    line-height: 17px;
                    font-weight: 600;
                    color: var(--font-color);
                    border: 1px solid var(--font-color);
                    @media (max-width: 600px) {
                        font-size: 11px;
                        line-height: 15px;
                        padding: 6px 12px;
                    }
                }
            }
        }
    }
}

.input-group {
    border: 1px solid $gray1;
    border-radius: 10px;

    @media (max-width: 1024px) {
        border-radius: 8px;
    }

    @media (max-width: 820px) {
        border-radius: 5px;
    }

    &-text, .form-control {
        background-color: transparent;
        color: $gray1;
        border: none;
        padding: 12px;
        margin-bottom: 0;

        @media (max-width: 1024px) {
            padding: 8px;
        }

        @media (max-width: 820px) {
            font-size: 12px;
            padding: 11px;
        }

        @media (max-width: 600px) {
            padding: 5px 8px;
        }
    }
}

.form-control {
    &:focus {
        box-shadow: none;
    }
}

.header-search-wrap {
    @media (max-width: 1200px) {
        flex-direction: column;
    }
}

.search-wrap{
    .liquidation-input{
        flex-flow: nowrap;
    }
}

.liquidation-table{
    
    td{
        text-align: center; 
    }
    .ratio-loader{
        div{
            align-items: center;
            justify-content: center;
        }
        
    }
    .copy-address{
        cursor: pointer;
    }
}