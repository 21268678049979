:root{
  --background: #1f1c2b;
  --text-color: #ffff;
}

.dark-theme {
  --background: #1f1c2b;
  --text-color: #ffff;
}

.light-theme {
  --background: #ffff;
  --text-color: #000;
 
}

.notify{
 padding: 10px;
 z-index: 10;
 background-color: var(--background);
 color: var(--text-color);
 margin-top: 4rem;
 margin-left: 58%;
 width: 37rem;
 position: absolute;
 transition: opacity .5s ease;
 box-shadow: 0px 0px 12px 12px rgba(151, 150, 150, 0.2);

@media (max-width: 1400px) {
    margin-left: 48%;
    width: 37rem;
    margin-top: 5rem;
  }
@media (max-width: 1300px) {
    margin-left: 38%;
    width: 37rem;
    margin-top: 5rem;
  }

@media (max-width: 920px) {
  margin-left: 22%;
  width: 37rem;
  margin-top: 5rem;
}

@media (max-width: 780px) {
    margin-left: 16%;
    width: 37rem;
    margin-top: 5rem;
  }
@media (max-width: 720px) {
    margin-left: 26%;
    width: 22rem;
    margin-top: 5rem;
  }

@media (max-width: 600px) {
    margin-left: 14%;
    width: 22rem;
    margin-top: 7rem;
  }

@media (max-width: 450px) {
    margin-left: 8%;
    width: 17rem;
    margin-top: 7rem;
  }
@media (max-width: 400px) {
    margin-left: 8%;
    width: 17rem;
    margin-top: 7rem;
  }
 }
 .notification-div{
  padding: 12px 12px 12px 12px;
  /* display: flex; */
  max-height: 70vh;
  overflow-y: scroll;

  hr{
    color: #fff !important;
  }
 }

  .notification-div::-webkit-scrollbar {
   width: 0px;
   background: transparent;
   /* make scrollbar transparent */
  }

.notify-hidden{
 padding: 10px;
 z-index: 1;
 background-color: var(--background);
 color: var(--text-color);
 margin-top: 4rem;
 margin-left: 58%;
 width: 37rem;
 position: absolute;
 opacity: 0;
 transition: opacity .5s ease;

@media (max-width: 1400px) {
    margin-left: 48%;
    width: 37rem;
    margin-top: 5rem;
  }
@media (max-width: 1300px) {
    margin-left: 38%;
    width: 37rem;
    margin-top: 5rem;
  }

@media (max-width: 920px) {
  margin-left: 22%;
  width: 37rem;
  margin-top: 5rem;
}

@media (max-width: 780px) {
  margin-left: 16%;
  width: 37rem;
  margin-top: 5rem;
}

@media (max-width: 720px) {
    margin-left: 26%;
    width: 22rem;
    margin-top: 5rem;
  }

@media (max-width: 600px) {
  margin-left: 14%;
  width: 22rem;
  margin-top: 7rem;
}

  @media (max-width: 450px) {
    margin-left: 8%;
    width: 17rem;
    margin-top: 7rem;
  }

  @media (max-width: 400px) {
    margin-left: 8%;
    width: 17rem;
    margin-top: 7rem;
  }
 
}

.notification {
 z-index: 10;
 background-color: var(--background);
 color: var(--text-color);
 margin-top: 4rem;
 margin-left: 58%;
 width: 37rem;
 position: absolute;
 transition: opacity 1s ease;
 box-shadow: 0px 0px 12px 12px rgba(151, 150, 150, 0.2);
 overflow: hidden;

@media (max-width: 1400px) {
    margin-left: 48%;
    width: 37rem;
    margin-top: 5rem;
  }
@media (max-width: 1300px) {
    margin-left: 38%;
    width: 37rem;
    margin-top: 5rem;
  }

@media (max-width: 920px) {
  margin-left: 22%;
  width: 37rem;
  margin-top: 5rem;
}

@media (max-width: 780px) {
  margin-left: 16%;
  width: 37rem;
  margin-top: 5rem;
}

@media (max-width: 720px) {
    margin-left: 26%;
    width: 22rem;
  }

@media (max-width: 600px) {
  margin-left: 14%;
  width: 22rem;
  margin-top: 7rem;
}

@media (max-width: 450px) {
    margin-left: 8%;
    width: 17rem;
    margin-top: 7rem;
  }

  @media (max-width: 420px) {
    margin-left: 8%;
    width: 17rem;
    margin-top: 7rem;
  }
  @media (max-width: 400px) {
    margin-left: 8%;
    width: 17rem;
    margin-top: 7rem;
  }
  @media (max-width: 390px) {
    margin-left: 8%;
    width: 17rem;
    margin-top: 7rem;
  }
}

.not-des{
 padding: 10px 10px 10px 10px;
}

.notif-text-No {
  cursor: pointer;
  
  }
  

